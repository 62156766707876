import { useCallback, useEffect, useRef } from 'react';
import useRecursiveTimeout from './useRecursiveTimeout';

export default function useAutoplay({ callback, interval }) {
  const { status, play, pause, stop } = useRecursiveTimeout(callback, interval);

  const autoplayRenewTimerRef = useRef(null);
  const clearAutoplayPause = useCallback(() => {
    if (autoplayRenewTimerRef.current) {
      clearTimeout(autoplayRenewTimerRef.current);
    }
  }, []);

  useEffect(() => clearAutoplayPause, [clearAutoplayPause]);

  const renewableStopHandler = useCallback(() => {
    clearAutoplayPause();

    stop();
    autoplayRenewTimerRef.current = setTimeout(play, 0);
  }, [stop, play, clearAutoplayPause]);

  return {
    status,
    startHandler: play,
    pauseHandler: pause,
    stopHandler: stop,
    renewableStopHandler,
  };
}
