import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { useInView } from 'react-intersection-observer';

import useIsMounted from 'hooks/useIsMounted';
import usePreviousValue from 'hooks/usePreviousValue';
import { MIN_DESKTOP_SCREEN, useLayoutContext } from 'utilities/layoutContext';

import useAutoplay from './useAutoplay';

const OBSERVER_THRESHOLD = [0.15, 0.75];

export default function useCarousel({ initialInterval }) {
  const isMounted = useIsMounted();
  const { viewportWidth, isTabletLayout } = useLayoutContext();

  const isDesktop = useMemo(() => {
    if (!isMounted) {
      return false;
    }

    return viewportWidth >= MIN_DESKTOP_SCREEN;
  }, [isMounted, viewportWidth]);

  const prevIsDesktop = usePreviousValue(isDesktop);
  const prevIsTablet = usePreviousValue(isTabletLayout);

  const { ref: viewRef, inView } = useInView({ threshold: OBSERVER_THRESHOLD });
  const prevInView = usePreviousValue(inView);

  const [isCarouselActive, setCarouselActiveTo] = useState(false);
  const [carouselRef, carouselApi] = useEmblaCarousel({
    align: isTabletLayout ? 'start' : 'center',
    loop: true,
  });

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const autoplayInterval = useMemo(() => {
    const parsedInterval = Number.parseInt(initialInterval, 10);
    if (Number.isNaN(parsedInterval)) {
      return 3000;
    }

    return parsedInterval;
  }, [initialInterval]);

  const autoplayHandler = useCallback(() => {
    if (!isCarouselActive || !carouselApi) {
      return;
    }

    carouselApi.scrollNext();
  }, [isCarouselActive, carouselApi]);

  const { status, renewableStopHandler, startHandler, pauseHandler, stopHandler } = useAutoplay({
    interval: autoplayInterval,
    callback: autoplayHandler,
  });

  const selectHandler = useCallback(() => {
    if (!carouselApi) {
      return;
    }

    setSelectedIndex(carouselApi.selectedScrollSnap());
    renewableStopHandler();
  }, [carouselApi, setSelectedIndex, renewableStopHandler]);

  const actionHandlerCreator = useCallback(
    (callback) => (...arg) => {
      if (!callback) {
        return;
      }

      callback(...arg);
    },
    [],
  );

  const scrollHandler = actionHandlerCreator(carouselApi?.scrollTo);
  const scrollPrevHandler = actionHandlerCreator(carouselApi?.scrollPrev);
  const scrollNextHandler = actionHandlerCreator(carouselApi?.scrollNext);

  useEffect(() => {
    if (!isDesktop && (prevIsDesktop || prevIsDesktop === undefined)) {
      setCarouselActiveTo(true);
    }

    if (isDesktop && prevIsDesktop !== undefined && !prevIsDesktop) {
      setCarouselActiveTo(false);
      setScrollSnaps([]);
    }

    if (carouselApi) {
      if (isTabletLayout !== prevIsTablet) {
        carouselApi.reInit({
          align: isTabletLayout ? 'start' : 'center',
          loop: true,
          startIndex: 0,
        });
        setSelectedIndex(0);
      }

      if (!isDesktop) {
        setScrollSnaps(carouselApi.scrollSnapList());
        carouselApi.on('select', selectHandler);
        carouselApi.on('pointerDown', pauseHandler);
        carouselApi.on('pointerUp', startHandler);
      }
    }
  }, [
    carouselApi,
    selectHandler,
    setScrollSnaps,
    setSelectedIndex,
    isTabletLayout,
    prevIsTablet,
    isDesktop,
    prevIsDesktop,
    pauseHandler,
    startHandler,
  ]);

  useEffect(() => {
    if (prevInView === undefined || inView === prevInView) {
      return;
    }

    if (inView) {
      startHandler();
    } else {
      stopHandler();
    }
  }, [inView, prevInView, startHandler, stopHandler]);

  return {
    autoplayInterval,
    selectedIndex,
    scrollSnaps,
    refs: {
      viewRef,
      carouselRef,
    },
    statuses: {
      isCarouselActive,
      animationStatus: status,
      isDesktop,
    },
    handlers: {
      scrollTo: scrollHandler,
      scrollToNext: scrollNextHandler,
      scrollToPrev: scrollPrevHandler,
      startAutoplay: startHandler,
      pauseAutoplay: pauseHandler,
    },
  };
}
