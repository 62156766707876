import { useState, useRef, useEffect, useCallback } from 'react';
import usePreviousValue from 'hooks/usePreviousValue';

export const STATUSES = {
  PLAY: 'play',
  PAUSE: 'pause',
  STOP: 'stop',
};

export default function useRecursiveTimeout(callback, delay) {
  const [runningStatus, setRunningStatusTo] = useState(STATUSES.STOP);
  const prevRunningStatus = usePreviousValue(runningStatus);

  const timerRef = useRef(null);

  const startTimeRef = useRef(null);
  const resumeDelayRef = useRef(delay);

  const clearTimerHandler = useCallback(() => {
    if (runningStatus === STATUSES.STOP) {
      resumeDelayRef.current = delay;
    }

    if (timerRef.current) {
      if (runningStatus === STATUSES.PAUSE) {
        resumeDelayRef.current -= Date.now() - startTimeRef.current;
      }

      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, [runningStatus, delay]);

  const play = useCallback(() => setRunningStatusTo(STATUSES.PLAY), []);
  const pause = useCallback(() => setRunningStatusTo(STATUSES.PAUSE), []);
  const stop = useCallback(() => setRunningStatusTo(STATUSES.STOP), []);

  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => stop, [stop]);

  useEffect(() => {
    const isRunning = runningStatus === STATUSES.PLAY;

    if (!isRunning) {
      if (prevRunningStatus !== undefined && runningStatus !== prevRunningStatus) {
        clearTimerHandler();
      }
      return;
    }

    const tick = () => {
      callbackRef.current();
      requestAnimationFrame(() => {
        clearTimerHandler();

        startTimeRef.current = Date.now();
        resumeDelayRef.current = delay;
        timerRef.current = setTimeout(tick, resumeDelayRef.current);
      });
    };

    if (timerRef.current == null) {
      requestAnimationFrame(() => {
        clearTimerHandler();

        startTimeRef.current = Date.now();
        timerRef.current = setTimeout(tick, resumeDelayRef.current);
      });
    }
  }, [runningStatus, prevRunningStatus, delay, clearTimerHandler]);

  return { status: runningStatus, play, stop, pause };
}
