import React from 'react';
import cls from 'classnames';

import { ALIGN_VARIANTS, COLOR_VARIANTS, SIZE_VARIANTS } from '../constants';
import { propTypes, defaultProps } from '../propTypes';

import styles from './Component.module.scss';

function Paragraphs({ children, className, color, size, align }) {
  return (
    <div
      className={cls(
        styles.Paragraphs,
        styles[`${color}Color`],
        styles[`${size}Size`],
        styles[`${align}Align`],
        className,
      )}
    >
      {React.Children.map(children, (item) => (
        <p className={styles.item} dangerouslySetInnerHTML={{ __html: item }} />
      ))}
    </div>
  );
}

Paragraphs.propTypes = propTypes;
Paragraphs.defaultProps = defaultProps;

export { ALIGN_VARIANTS, COLOR_VARIANTS, SIZE_VARIANTS };
export default Paragraphs;
