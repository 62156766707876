import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { STATUSES as ANIMATION_STATUSES } from '../../hooks/useRecursiveTimeout';

import styles from './DotItem.module.scss';

function DotItem({
  index,
  selected,
  animationStatus,
  autoplayInterval,
  onClick,
  onStartAnimation,
  onPauseAnimation,
}) {
  const clickHandler = useCallback(() => {
    onClick(index);
  }, [onClick, index]);

  return (
    <button
      className={cls(
        styles.DotItem,
        selected && styles.selected,
        animationStatus === ANIMATION_STATUSES.PAUSE && styles.paused,
        animationStatus === ANIMATION_STATUSES.STOP && styles.stopped,
      )}
      type="button"
      onClick={clickHandler}
      onTouchStart={onPauseAnimation}
      onMouseEnter={onPauseAnimation}
      onTouchEnd={onStartAnimation}
      onMouseLeave={onStartAnimation}
    >
      {index}
      <span className={styles.indicator} style={{ animationDuration: `${autoplayInterval}ms` }} />
    </button>
  );
}

DotItem.propTypes = {
  index: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  animationStatus: PropTypes.oneOf(Object.values(ANIMATION_STATUSES)).isRequired,
  autoplayInterval: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  onStartAnimation: PropTypes.func.isRequired,
  onPauseAnimation: PropTypes.func.isRequired,
};

export default DotItem;
