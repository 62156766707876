import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import styles from './ArrowIcon.module.scss';

export const DIRECTIONS = {
  LEFT: 'left',
  RIGHT: 'right',
};

function ArrowIcon({ className, pathClassName, direction }) {
  return (
    <svg
      className={cls(styles.ArrowIcon, styles[`${direction}Direction`], className)}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={cls(styles.path, pathClassName)}
        d="M9.29001 15.46L13.17 11.58L9.29001 7.70001C9.19742 7.60742 9.12399 7.49751 9.07388 7.37655C9.02378 7.25559 8.99799 7.12594 8.99799 6.99501C8.99799 6.86408 9.02378 6.73443 9.07388 6.61346C9.12399 6.4925 9.19742 6.38259 9.29001 6.29001C9.38259 6.19742 9.4925 6.12398 9.61346 6.07388C9.73443 6.02377 9.86408 5.99799 9.99501 5.99799C10.1259 5.99799 10.2556 6.02377 10.3765 6.07388C10.4975 6.12398 10.6074 6.19742 10.7 6.29001L15.29 10.88C15.68 11.27 15.68 11.9 15.29 12.29L10.7 16.88C10.6075 16.9727 10.4976 17.0463 10.3766 17.0964C10.2557 17.1466 10.126 17.1724 9.99501 17.1724C9.86404 17.1724 9.73436 17.1466 9.61338 17.0964C9.49241 17.0463 9.38252 16.9727 9.29001 16.88C8.91001 16.49 8.90001 15.85 9.29001 15.46Z"
      />
    </svg>
  );
}

ArrowIcon.propTypes = {
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  direction: PropTypes.oneOf(Object.values(DIRECTIONS)),
};

ArrowIcon.defaultProps = {
  className: undefined,
  pathClassName: undefined,
  direction: DIRECTIONS.RIGHT,
};

export default ArrowIcon;
