import React, { useCallback } from 'react';
import cls from 'classnames';

import Container from 'components/base/Container';

import Title, {
  TAG_VARIANTS as TITLE_TAGS,
  SIZE_VARIANTS as TITLE_SIZES,
  ALIGN_VARIANTS as TITLE_ALIGN,
} from 'components/atoms/Title/Component';
import Paragraphs, {
  ALIGN_VARIANTS as PARAGRAPHS_ALIGN,
} from 'components/atoms/Paragraphs/Component';
import ArrowIcon, { DIRECTIONS } from 'components/icons/ArrowIcon';

import { propTypes, defaultProps } from '../propTypes';

import DotItem from './blocks/DotItem';

import useCarousel from './hooks/useCarousel';
import { STATUSES as ANIMATION_STATUSES } from './hooks/useRecursiveTimeout';

import commonStyles from '../Common.module.scss';

function AdvantagesList({ anchor, title, list, autoplayInterval: initialInterval }) {
  const {
    autoplayInterval,
    selectedIndex,
    scrollSnaps,
    refs,
    statuses,
    handlers: { startAutoplay, pauseAutoplay, ...handlers },
  } = useCarousel({
    initialInterval,
  });

  const renderControl = useCallback(
    (next, onClick) => (
      <button
        className={cls(
          commonStyles.carouselControl,
          next ? commonStyles.carouselRightControl : commonStyles.carouselLeftControl,
        )}
        type="button"
        onClick={onClick}
        title={next ? 'next' : 'previous'}
      >
        <div className={commonStyles.carouselControlWrapper}>
          <ArrowIcon direction={next ? DIRECTIONS.RIGHT : DIRECTIONS.LEFT} />
        </div>
      </button>
    ),
    [],
  );

  const startAnimationHandler = useCallback(() => {
    if (statuses.animationStatus !== ANIMATION_STATUSES.PLAY) {
      startAutoplay();
    }
  }, [startAutoplay, statuses.animationStatus]);

  const pauseAnimationHandler = useCallback(() => {
    if (statuses.animationStatus === ANIMATION_STATUSES.PLAY) {
      pauseAutoplay();
    }
  }, [pauseAutoplay, statuses.animationStatus]);

  return (
    <div id={anchor} className={commonStyles.ComponentRoot} ref={refs.viewRef}>
      <Container>
        <div className={commonStyles.wrapper}>
          <div className={commonStyles.content}>
            <Title tag={TITLE_TAGS.h2} align={TITLE_ALIGN.CENTER}>
              {title}
            </Title>
            <div className={commonStyles.carousel}>
              {renderControl(false, handlers.scrollToPrev)}
              <div
                ref={statuses.isCarouselActive ? refs.carouselRef : null}
                className={commonStyles.carouselContainer}
              >
                <div className={commonStyles.list}>
                  {list.map(({ key, illustration, paddingTop, ...item }) => (
                    <div
                      key={key}
                      className={commonStyles.item}
                      style={statuses.isDesktop && paddingTop ? { paddingTop } : undefined}
                    >
                      <div className={commonStyles.itemContent}>
                        {illustration && (
                          <div className={commonStyles.itemIllustrationWrapper}>
                            <img
                              className={commonStyles.itemIllustration}
                              src={illustration.src}
                              alt={illustration.alt}
                              role={illustration.presentation ? 'presentation' : undefined}
                            />
                          </div>
                        )}
                        <Title
                          tag={TITLE_TAGS.div}
                          size={TITLE_SIZES.h4}
                          align={TITLE_ALIGN.CENTER}
                        >
                          {item.title}
                        </Title>
                        <Paragraphs
                          className={commonStyles.itemParagraphs}
                          align={PARAGRAPHS_ALIGN.CENTER}
                        >
                          {item.paragraphs}
                        </Paragraphs>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {renderControl(true, handlers.scrollToNext)}
            </div>
            {scrollSnaps.length ? (
              <div className={commonStyles.dots}>
                {scrollSnaps.map((_, index) => (
                  <DotItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    index={index}
                    selected={selectedIndex === index}
                    animationStatus={statuses.animationStatus}
                    autoplayInterval={autoplayInterval}
                    onClick={handlers.scrollTo}
                    onStartAnimation={startAnimationHandler}
                    onPauseAnimation={pauseAnimationHandler}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </Container>
    </div>
  );
}

AdvantagesList.propTypes = propTypes;
AdvantagesList.defaultProps = defaultProps;

export default AdvantagesList;
