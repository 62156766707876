import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { ALIGN_VARIANTS, COLOR_VARIANTS, TAG_VARIANTS, SIZE_VARIANTS } from '../constants';
import { propTypes, defaultProps } from '../propTypes';

import commonStyles from '../Common.module.scss';
import componentStyles from './Component.module.scss';

const selfClassNames = {
  [TAG_VARIANTS.h1]: componentStyles.h1,
  [TAG_VARIANTS.h2]: componentStyles.h2,
  [TAG_VARIANTS.h3]: componentStyles.h3,
  [TAG_VARIANTS.h4]: componentStyles.h4,
  [TAG_VARIANTS.div]: componentStyles.div,
};

function Title({ tag, size, children, color, align, className }) {
  const Tag = tag in TAG_VARIANTS ? tag : TAG_VARIANTS.div;

  return (
    <div
      className={cls(
        selfClassNames[size ?? Tag],
        componentStyles[`${color}Color`],
        componentStyles[`${align}Align`],
        className,
      )}
    >
      <Tag
        className={cls(commonStyles.content, componentStyles.content)}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </div>
  );
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes,
};
Title.defaultProps = defaultProps;

export { ALIGN_VARIANTS, COLOR_VARIANTS, TAG_VARIANTS, SIZE_VARIANTS };
export default Title;
